<template>
  <div class="app-container">
    
    <nav-bar></nav-bar>

    <!-- <img class="logo" src="@assets/logo.png" alt="" /> -->

    <div class="question-list">
      <div class="title">
        <div class="lt">尝试这样问我</div>
        <div class="rt" @click="getRecommend"><img src="@assets/refresh.png" alt="">换一批</div>
      </div>
      <div class="list">
        <div class="item" v-for="item in data.recommendList" :key="item.recommendId" @click="send(item.recommendTitle)">
          {{ item.recommendTitle }}
        </div>
      </div>
    </div>
    <bottom-bar @send="send" :showExtra=false></bottom-bar>

  </div>
</template>

<script setup>
import { showDialog } from 'vant';

import NavBar from '../../components/nav-bar.vue';
import { getRecommendList } from './api.js';
import { ref, reactive, onMounted } from 'vue';
import BottomBar from '@/components/bottom-bar';
import { useRouter } from 'vue-router'
const router = useRouter()
// const sendMessage = message.sendMessage(textData);

const data = reactive({
  recommendList: [],
});
onMounted(() => {
  if(sessionStorage.getItem('showD')){
    getRecommend();
  }else{
    sessionStorage.setItem('showD', 1)
    // showDialog({
    //   title: '提示',
    //   message: '禁止利用AI功能从事违法相关活动！',
    // }).then(() => {
    getRecommend();
    // });
  }
  
});
const recommendPage = ref({
  pageSize: 6,
  pageNum: 1
})
function getRecommend() {
  getRecommendList(recommendPage.value).then((res) => {
    data.recommendList = res.data;
    recommendPage.value.pageNum++
  });
}

function send(text) {
  if(localStorage.getItem('reloadPage')){
    localStorage.removeItem("reloadPage");
  }
  router.push({
    name: 'message',
    query: {
      text: text
    }
  })
}

</script>

<style lang="less" scoped>
.app-container {
  padding-bottom: 80px;
  overflow-y: auto;
  height: calc(100vh - 150px);

  .logo {
    width: 150px;
    height: 131px;
    margin: 16px auto;
    display: block;
    margin-top: 40px;
  }

  .question-list {
    margin: 16px;
    margin-top: 48px;
    padding: 16px 0;
    padding-bottom: 0;
    background: #fff;
    border-radius: 16px;

    .title {
      padding: 0 16px;

      display: flex;
      justify-content: space-between;

      .lt {
        font-weight: 700;
        font-size: 16px;
        color: #1D1D2C;
        position: relative;
        z-index: 2;

        &::after {
          z-index: 1;
          position: absolute;
          left: 0;
          bottom: 0;
          content: '';
          width: 96px;
          height: 6px;
          background-image: linear-gradient(90deg, #5381FF 0%, #ffffff00 100%);
          opacity: 0.6;
          border-radius: 5px;
        }
      }

      .rt {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #494A57;
        gap: 4px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }


    .list {
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      .item {
        font-size: 14px;
        color: #1D1D2C;
        line-height: 20px;
        padding: 12px 16px;
        border-bottom: 1px solid #F5F7F9;

      }
    }


  }
}</style>
